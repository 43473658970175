<template>
  <div class="support">
    <div class="support-info">
      <div class="support-info__content">
        <page-title class="support-info__title">
          {{ title }}
        </page-title>
      </div>
      <div class="support-info__create">
        <div class="mr">
          <base-button
            v-if="(isAstra && list && list.length) || currentRoute"
            slot="link"
            @click="addNewTicketCrm"
          >
            {{ newTicket }}
          </base-button>
          <base-button
            v-else-if="(!isAstra && list && list.length) || currentRoute"
            @click="addNewTicket"
          >
            {{ newTicket }}
          </base-button>
        </div>
        <div>
          <base-button theme="outlined" color="primary" @click="openUrl">
            {{ $t('faq') }}
          </base-button>
        </div>
      </div>
    </div>
    <tabs v-if="!isAstra" :list="allowedNav" class="support__tabs medium-title">
      <template v-slot:item="{ item }">
        <!--        <router-link exact-active-class="active" tag="div" :to="item.to">-->
        <router-link active-class="active" :to="item.to">
          <!--        <router-link exact-active-class="active" tag="div" :to="item.to">-->
          {{ item.title }}
        </router-link>
      </template>
    </tabs>
    <div class="support__content">
      <transition name="slide-fade">
        <router-view :loading="loading"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs/Tabs.vue';
import storeMixin from '../mixins/index';

export default {
  name: 'SupportMain',
  components: {
    Tabs,
  },
  mixins: [storeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadPage: true,
      newTicket: this.$t('new_ticket'),
      title: this.$t('label'),
      nav: [
        {
          title: this.$t('nav.open'),
          to: { name: 'OpenTicket' },
        },
        {
          title: this.$t('nav.arhiv'),
          to: { name: 'ArchiveRequest' },
          access: { name: 'isArhiveTickects', value: true },
        },
        {
          title: this.$t('nav.incident'),
          to: { name: 'Incidents', query: { filter_by: 'report', value: '' } },
          access: {
            name: 'isArhiveTickects',
            value: true,
          },
        },
      ],
    };
  },
  computed: {
    isAstra() {
      return this.$store.state.moduleProviders.current === '6';
    },
    supports() {
      return this.$store.state.moduleSupport.allTickets;
    },
    allowedNav() {
      return this.nav.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },

    currentRoute() {
      return this.$route.name === 'ArchiveRequest';
    },
    isArhiveTickects() {
      return this.$store.getters['moduleSupport/GET_ALL_TICKETS_ARHIVE'].length > 0;
    },
    isIncident() {
      return this.$store.getters['moduleSupport/GET_ALL_INCIDENT'].length > 0;
    },
  },
  watch: {
    isAstra: {
      handler(val) {
        // console.log(val);
        // this.updateSort(val);
        // if (val) this.crmAuth();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store
      .dispatch('moduleSupport/fetchTicketsFilter')
      .then(async () => await this.$store.dispatch('moduleSupport/fetchAllTickets'));
    this.$store.dispatch('moduleSupport/fetchAllIncedent');
    this.$store.dispatch('moduleSupport/resetFiles');
    this.$store.dispatch('moduleSupport/fetchServiceList');

    this.fetchAllTicketsArhive();
  },
  methods: {
    openUrl() {
      window.open('https://support.rusonyx.ru/index.php?/Knowledgebase/List', '_blanc');
    },
    fetchAllTicketsArhive() {
      // this.isLoading = true;
      return this.$store
        .dispatch('moduleSupport/fetchAllTicketsArhive')
        .finally(() => (this.loadPage = false));
    },
  },
};
</script>

<i18n>
{
"ru":{
  "new_ticket": "Новый запрос",
  "label": "Поддержка",
  "faq": "База знаний",
  "nav": {
    "open": "Список открытых запросов",
    "arhiv": "Архив обращений",
    "incident": "Инциденты",
    "notifications": "Уведомления"
  }
}
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.mr {
  margin-right: 1.5rem

}
.support {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__tabs {
    cursor: pointer;
  }

  &-info {
    +breakpoint(sm-and-up) {
    margin-bottom 1.5rem;
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__create {
      //flex: 0 0 auto;
      display: flex;
      flex-direction: row
      margin-top: 0.5rem;
      max-width: 320px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &__content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 1.5rem;
    }
  }
}
</style>
